import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import chooseImg from '../../assets/images/why-choose-us.png';
import './choose-us.css';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

const ChooseUsData = [
  {
    title: 'Communication',
    desc: 'Sharing thoughts, questions, ideas and solutions'
  },

  {
    title: 'Collaboration',
    desc: 'Working together to reach a goal. Putting talent, expertise, and smarts to work.'
  },

  {
    title: 'Critical Thinking',
    desc: 'Looking a problems in a new way and linking learning across subjects and disciplines'
  },
  {
    title: 'Creativity',
    desc: 'Trying new approaches to get things done equals innovation and invention.'
  }
];

const ChooseUs = () => {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <section className="pb-0">
      <Container>
        <h2 className="mb-4 text-center">Why Choose Us</h2>
        <Row>
          <Col lg="6" md="6">
            <Row>
              <div className="text-xs-center">
                <p>
                  We focus on 4 Cs of 21st century learning thereby guiding you to achieve the
                  skills of the four cs are all skills that are needed in order to succeed in
                  today's world.
                </p>
              </div>
              {ChooseUsData.map((item, index) => (
                <Col lg="6" md="6" key={index}>
                  <div className="text-justify text-xs-center px-3">
                    <h6>{item.title}</h6>
                    <p>{item.desc}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>

          <Col lg="6" md="6">
            <div className="choose__img">
              {showVideo ? (
                <ReactPlayer
                  url="https://youtu.be/akctSO6EGMw"
                  width="100%"
                  height="350px"
                  playsinline
                />
              ) : (
                <img src={chooseImg} alt="" className="w-100" />
              )}

              {!showVideo && (
                <span className="play__icon">
                  <i className="ri-play-circle-line" onClick={() => setShowVideo(!showVideo)}></i>
                </span>
              )}
            </div>
          </Col>
          <div className="mt-3 mb-3 text-center">
            <Link to={'/about-us'}>
              <button className="btn">Know more about us</button>
            </Link>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default ChooseUs;
