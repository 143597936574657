import React from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import './map.css';

const libraries = ['places'];
const Maps = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY,
    libraries
  });
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <GoogleMap
      zoom={15}
      center={{ lat: 27.454895558787342, lng: 83.90398049495246 }} //27.454905079277516, 83.90396373114737 //27.454895558787342, 83.90398049495246
      mapContainerClassName="map-container"
    >
      <Marker position={{ lat: 27.454895558787342, lng: 83.90398049495246 }} />
    </GoogleMap>
  );
};

export default Maps;
