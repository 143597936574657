import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import NoticesSection from '../components/Notices-section/NoticesSection';

const Notices = () => {
  return (
    <>
      <Header />
      <NoticesSection />
      <Footer />
    </>
  );
};

export default Notices;
