import Home from './pages/Home';
import Notices from './pages/Notices';
import Gallery from './pages/Gallery';
import { Routes, Route } from 'react-router-dom';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faPersonChalkboard, faBus, faHandsHoldingChild,faTrophy, faComputer, faFlaskVial, faBed, faUtensils, faImages, faBookOpenReader } from '@fortawesome/free-solid-svg-icons'
library.add(fab,faPersonChalkboard,faBus, faHandsHoldingChild, faTrophy, faComputer,faFlaskVial, faBed, faUtensils, faImages, faBookOpenReader)

function App() {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about-us" exact element={<AboutUs />} />
        <Route path="/notices" exact element={<Notices />} />
        <Route path="/gallery" exact element={<Gallery />} />
        <Route path="/contact-us" exact element={<ContactUs />} />
      </Routes>
    </>
  );
}

export default App;
