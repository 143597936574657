import React from 'react';
import Header from '../components/Header/Header';
import HeroSection from '../components/Hero-Section/HeroSection';
import ChooseUs from '../components/Choose-us/ChooseUs';
import Features from '../components/Feature-section/Features';
import Testimonials from '../components/Testimonial/Testimonials';
import Footer from '../components/Footer/Footer';
// import Courses from '../components/Courses-section/Courses';
// import Grid from '../components/Grid-section/Grid-section';
// import LatestNotice from '../components/LatestNotice-section/LatestNotice';
import { Container } from 'reactstrap';

const Home = () => {
  return (
    <>
      <Header />
      <Container>
        <HeroSection />
        {/* <Grid /> */}
        {/* <Courses /> */}
        <Features />
        <ChooseUs />
        {/* <LatestNotice /> */}
        <Testimonials />
      </Container>
      <Footer />
    </>
  );
};

export default Home;
