import React from 'react';
import './testimonial.css';
import { Container, Row, Col } from 'reactstrap';
import Slider from 'react-slick';
import studentTalking from '../../assets/lotties/studentTalking.json';
import Lottie from 'lottie-react';
// import img from '../../assets/images/testimonial01.png';

const Testimonials = () => {
  const settings = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1
  };

  const testimonialDetails = [
    {
      title: 'Excellent School Culture',
      message:
        'My journey with Blooming Buds has been a great experience. It has all the facilities & a wide range of programs under one roof with excellent infrastructure, smart, inspiring and well-experienced, professional teachers, modern computer labs, and dedication to students.',
      by: 'Nikit Rauniyar',
      address: 'Alumni (Software Engineer in Australia)'
    },
    {
      title: 'Exceptional Learning',
      message:
        'I am inspired and motivated by the professionally designed courses with pragmatic teaching methods of scholarly faculty. All these have helped me grow professionally and personally. It is a place where learning meets thinking, and passion meets purpose which guides us to the future.',
      by: 'Raj Tamang',
      address: 'Butwal, NP'
    }
  ];
  return (
    <section>
      <Container>
        <h2 className="mb-4 text-center">Our Students Voice</h2>
        <Row>
          <Col lg="6" md="6" className="m-auto">
            <div className="testimonial__img w-100">
              <Lottie animationData={studentTalking} />
              {/* <img src={img} alt="" className="w-100" /> */}
            </div>
          </Col>
          <Col lg="6" md="6" className="m-auto">
            <div className="testimonial__wrapper text-xs-center align-items-center ">
              <div className="testimonial__content w-100">
                <Slider {...settings}>
                  {testimonialDetails.map((testimonialDetail, index) => (
                    <div key={index}>
                      <div className="single__testimonial">
                        <h6 className="mb-3 fw-bold">{testimonialDetail.title}</h6>
                        <p>{testimonialDetail.message}</p>

                        <div className="student__info mt-4">
                          <h6 className="fw-bold">{testimonialDetail.by}</h6>
                          <p>{testimonialDetail.address}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonials;
