import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import GallerySection from '../components/Gallery-section/gallery-section';

const Gallery = () => {
  return (
    <>
      <Header />
      <GallerySection />
      <Footer />
    </>
  );
};

export default Gallery;
