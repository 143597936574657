import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './features.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {} from '@fortawesome/free-solid-svg-icons';
const FeatureData = [
  {
    title: 'Quality Teaching',
    icon: 'person-chalkboard'
  },

  {
    title: 'Childcare',
    icon: 'hands-holding-child'
  },
  {
    title: 'Transportation',
    icon: 'bus'
  },

  {
    title: 'Sports',
    icon: 'trophy'
  },
  {
    title: 'Computer Lab',
    icon: 'computer'
  },
  {
    title: 'Laboratories',
    icon: 'flask-vial'
  },
  {
    title: 'Hostel',
    icon: 'bed'
  },
  {
    title: 'Cafeteria',
    icon: 'utensils'
  }
];

const Features = () => {
  return (
    <section className="pb-0">
      <Container>
        <h2 className="mb-4 text-center">Our Facilities and Services</h2>
        {/* <FontAwesomeIcon icon={faCoffee} /> */}
        <Row>
          {FeatureData.map((item, index) => (
            <Col lg="3" md="6" xs="6" key={index}>
              <div className="single__feature text-center px-4">
                <h2 className="mb-3">
                  <FontAwesomeIcon icon={item.icon} size="2x" border />
                </h2>
                <h6 className="mb-5">{item.title}</h6>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Features;
