import React from 'react';

const NoticeCard = ({ notice }) => {
  const { imgUrl, title, publishedDate } = notice;

  return (
    <div className="single__notice__item">
      <div className="notice__img">
        <img src={imgUrl} alt="" className="w-100" />
      </div>

      <div className="notice__details">
        <h6 className="notice__title">{title}</h6>

        {/* <div className=" d-flex justify-content-between align-items-center"> */}
        {/* <p className="lesson d-flex align-items-center gap-1">
            <i className="ri-book-open-line"></i> {lesson} Lessons
          </p>
          
          <p className="students d-flex align-items-center gap-1">
          <i className="ri-user-line"></i> {students}K
        </p> */}
        <p className="lesson">Published on {publishedDate}</p>
        {/* </div> */}

        {/* <div className=" d-flex justify-content-between align-items-center">
          <p className="rating d-flex align-items-center gap-1">
            <i className="ri-star-fill"></i> {rating}K
          </p>

          <p className="enroll d-flex align-items-center gap-1">
            <a href="#admission"> Enroll Now</a>
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default NoticeCard;
