import React from 'react';
import Header from '../components/Header/Header';
import { Col, Row } from 'reactstrap';
import Footer from '../components/Footer/Footer';
import ContactForm from '../components/Contact-form/contact-form';
import Maps from '../components/Map-section/Map';

const ContactUs = () => {
  return (
    <>
      <Header />
      <div className="container mt-5">
        <h2 className="mb-4 text-center">Contact Us</h2>
        <Row className="mb-4">
          <Col lg="12" md="12">
            <div className=" col-xs-12 col-md-12">
              <Maps />
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col lg="6" md="6">
            <h4 className="mb-3 mt-5 text-center">Contact Details</h4>

            <div className="mx-3 col-xs-12 col-md-12">
              <h6>Address:</h6>
              <p>Shivapurgadhi, Binayi Triveni - 6, Nawalparasi</p>
              <h6>Phone:</h6>
              <p>
                <a href="tel:+977-(0)78-560285" className="text-decoration-none">
                  +977-(0)78-560285
                </a>
              </p>
              <h6>Email:</h6>
              <p>
                <a href="mailto:info@bloomingbuds.edu.np" className="text-decoration-none">
                  info@bloomingbuds.edu.np
                </a>
              </p>
            </div>
          </Col>
          <ContactForm />
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
