import React, { useEffect, useState } from 'react';
import './about-section.css';
import { Container, Row, Col } from 'reactstrap';
import CountUp from 'react-countup';
import './about-section.css';
import school from '../../assets/lotties/company.json';
import Lottie from 'lottie-react';
import vision from '../../assets/lotties/vision.json';
import mission from '../../assets/lotties/target.json';
import speech from '../../assets/lotties/speech.json';

const AboutSection = () => {
  const [width, setWidth] = useState(window.innerWidth);

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, []);

  const aboutUsDetails = [
    {
      lastNumber: 500,
      description: 'Students',
      suffix: '+'
    },
    {
      lastNumber: 20,
      description: 'Staff',
      suffix: '+'
    },
    {
      lastNumber: 100,
      description: 'Satisfaction',
      suffix: '%'
    }
  ];
  return (
    <section>
      <Container id="about-us">
        <h2 className="text-center mb-4">About Us</h2>
        <Row className="mb-5">
          <Col lg="6" md="6">
            <div className="mb-3">
              <Lottie animationData={school} />
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__content text-xs-center">
              <p>
                Since our emergence in 2062 BS, we have helped thousands of students to find their
                paths forward. We focus on extracting their inner self rather than forcing them to
                become what is expected.
              </p>
              <p>
                And keeping that in mind, we have equipped ourselves with facilities and services
                like no other education provider. We always strive to improve ourselves and the
                surrounding our students call their second home.
              </p>
              <Row>
                {aboutUsDetails.map((aboutUsDetail, index) => (
                  <Col xs="6" key={index}>
                    <div className="single__counter">
                      <span className="counter">
                        <CountUp
                          start={0}
                          end={aboutUsDetail.lastNumber}
                          duration={4}
                          suffix={aboutUsDetail.suffix}
                        />
                      </span>

                      <p className="counter__title">{aboutUsDetail.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col lg="6" md="6">
            <div className="text-xs-center ">
              <h4 className="text-xs-center  mb-4">#Vision</h4>
              <p>
                To assist students in acquiring and developing the information, skills, values, and
                leadership characteristics required for personal and professional growth in the
                twenty-first century and beyond in a variety of fields.
              </p>
            </div>
          </Col>
          <Col lg="6" md="6">
            <Lottie animationData={vision} style={{ height: 300 }} />
          </Col>
        </Row>
        <Row className="mb-5">
          {width < 720 ? (
            <>
              <Col lg="6" md="6">
                <div className="text-xs-center">
                  <h4 className="text-xs-center mb-4">#Mission</h4>
                  <p>
                    To assist students in acquiring and developing the information, skills, values,
                    and leadership characteristics required for personal and professional growth in
                    the twenty-first century and beyond in a variety of fields.
                  </p>
                </div>
              </Col>
              <Col lg="6" md="6">
                <Lottie animationData={mission} style={{ height: 300 }} />
              </Col>
            </>
          ) : (
            <>
              <Col lg="6" md="6">
                <Lottie animationData={mission} style={{ height: 300 }} />
              </Col>
              <Col lg="6" md="6">
                <div className="text-xs-center">
                  <h4 className="text-xs-center mb-4">#Mission</h4>
                  <p>
                    The aim of this institute is to draw motivated students from all backgrounds,
                    encourage them to think and behave as principled citizens, develop them as wise
                    and principled leaders, and assist them in becoming who they promise to be. This
                    is done by helping them cultivate positive attitudes towards collaboration,
                    leadership, problem-solving, and decision-making while still keeping them rooted
                    in Nepal's heritage, history, and culture.
                  </p>
                </div>
              </Col>
            </>
          )}
        </Row>
        <Row>
          <h4 className="text-xs-center mb-4">Message from the Principal</h4>
          <Col lg="8" md="8">
            <div className="text-xs-center">
              <p>
                It is an honour for me to express my gratitude to everyone of our guardians and
                former students for their earnest efforts and useful recommendations that helped our
                institution grow. I want to express my gratitude to all the members of this
                institution who collaborated to give it a modern appeal.{' '}
              </p>{' '}
              <p>
                Here, a common commitment to education is made by passionate parents with greater
                expectations, committed teachers, and driven students. Today, a school's job is to
                inspire and empower students to be lifelong learners, critical and creative
                thinkers, and productive citizens of a rapidly changing, opportunity-rich global
                society in addition to pursuing academic achievement. Therefore, by utilising a
                holistic approach in the teaching-learning activities for their personal and
                professional growth, we sincerely try to unleash students' explanatory vision,
                increase their imagination, broaden their horizons, sharpen their innovative ideas,
                foster their positive thinking, expand their hopes, develop their confidence, and
                enhance their personality.{' '}
              </p>
              <p>
                The level of the school and the welfare of the students will be my top priorities as
                principal. We always operate on the assumption that every family, every parent, and
                every kid possesses greatness, and we work to maximise that brilliance. The entire
                team is dedicated to turning our institution become a model. I can confidently
                promise you that we will do all in our power to get you where you want to go by
                putting your ideas into action. I'm hoping the best for you. Finally, the secret to
                our success is your feedback.
              </p>
              <h6>
                - Santosh Kumar Rauniyar <br /> &ensp;(Principal, Blooming Buds)
              </h6>
            </div>
          </Col>
          <Col lg="4" md="4">
            <Lottie animationData={speech} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
