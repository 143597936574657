import React, { useRef } from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import './header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const navLinks = [
  {
    display: 'Home',
    url: '/'
  },
  {
    display: 'About Us',
    url: '/about-us'
  },
  {
    display: 'Admission',
    url: 'https://forms.office.com/r/HS7x2HDXdM',
    target: '_noblank'
  },
  // {
  //   display: 'Gallery',
  //   url: '/gallery'
  // },
  // {
  //   display: 'Notices',
  //   url: '/notices'
  // },
  {
    display: 'Contact Us',
    url: '/contact-us'
  }
];

const Header = () => {
  const menuRef = useRef();

  const menuToggle = () => menuRef.current.classList.toggle('active__menu');

  return (
    <header className="header">
      <Container id="home">
        <div className="navigation d-flex align-items-center justify-content-between">
          <a href="/" className="text-decoration-none text-reset">
            <div className="logo">
              <h2 className="d-flex align-items-center gap-1">
                <FontAwesomeIcon icon="book-open-reader" /> Blooming Buds
              </h2>
              <h5>&emsp;&ensp;Secondary Boarding School</h5>
            </div>
          </a>

          <div className="nav d-flex align-items-center gap-5">
            <div className="nav__menu" ref={menuRef} onClick={menuToggle}>
              <ul className="nav__list">
                {navLinks.map((item, index) => (
                  <li key={index} className="nav__item">
                    {item.target ? (
                      <a href={item.url} target={item.target}>
                        {item.display}
                      </a>
                    ) : (
                      <Link to={item.url}>{item.display} </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            <div className="nav__right">
              <p className="mb-0 d-flex align-items-center gap-2">
                <i className="ri-phone-line"></i> +977-(0)78-560285
              </p>
            </div>
          </div>

          <div className="mobile__menu">
            <span>
              <i className="ri-menu-line" onClick={menuToggle}></i>
            </span>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
