import React, { useState } from 'react';
import { Col } from 'reactstrap';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const [contactDetail, setContactDetail] = useState({
    name: '',
    contact: '',
    email: '',
    message: ''
  });
  const [formStatus, setFormStatus] = useState('Send');
  const onChange = (e) => {
    setContactDetail((prevContactDetail) => ({
      ...prevContactDetail,
      [e.target.name]: e.target.value
    }));
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      setFormStatus('Sending...');
      const result = await emailjs.sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAIL_PUBLIC_API_KEY
      );
      if (result) {
        setFormStatus('Sent');
        console.log('email sent');
        setContactDetail({
          name: '',
          contact: '',
          email: '',
          message: ''
        });
      }
    } catch (error) {
      console.log(error.text);
    }
  };
  return (
    <Col lg="6" md="6">
      <h4 className="mb-3 mt-5 text-center">Contact Form</h4>
      <div className="mx-3 col-xs-12 col-md-12">
        <form onSubmit={sendEmail}>
          <div className="mb-3">
            <label className="form-label" htmlFor="name">
              Name
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="name"
              onChange={onChange}
              value={contactDetail.name}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="contact">
              Contact Number
            </label>
            <input
              className="form-control"
              type="text"
              id="contact"
              name="contact"
              onChange={onChange}
              value={contactDetail.contact}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="email">
              Email
            </label>
            <input
              className="form-control"
              type="email"
              id="email"
              name="email"
              onChange={onChange}
              value={contactDetail.email}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="message">
              Message
            </label>
            <textarea
              className="form-control"
              id="message"
              onChange={onChange}
              value={contactDetail.message}
              name="message"
              required
            />
          </div>
          <button className="btn btn-success" type="submit">
            {formStatus}
          </button>
        </form>
      </div>
    </Col>
  );
};
export default ContactForm;
