import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, ListGroup, ListGroupItem, Table } from 'reactstrap';

import './footer.css';

const footerQuickLinks = [
  {
    display: 'Home',
    url: '/'
  },
  {
    display: 'Admission',
    url: 'https://forms.office.com/r/HS7x2HDXdM',
    target: '_noblank'
  }

  // {
  //   display: 'Gallery',
  //   url: '/gallery'
  // },

  // {
  //   display: 'Notices',
  //   url: '/notices'
  // }
];

const footerInfoLinks = [
  {
    display: 'About Us',
    url: '/about-us'
  },
  {
    display: 'Contact Us',
    url: '/contact-us'
  }

  // {
  //   display: 'Privacy Policy',
  //   url: '/privacy-policy'
  // },

  // {
  //   display: 'Terms of Service',
  //   url: '/terms-of-service'
  // }
];

const Footer = () => {
  return (
    <footer className="footer px-2 pb-0">
      <Container>
        <Row>
          <Col lg="3" md="6" className="mb-4">
            <a href="/" className="text-decoration-none text-reset">
              <h2 className=" d-flex align-items-center gap-1">
                <FontAwesomeIcon icon="book-open-reader" /> Blooming Buds.
              </h2>
            </a>

            <div className="follows">
              <a
                href="https://www.facebook.com/profile.php?id=100063697196994"
                className="text-decoration-none"
                target="_blank"
                rel="noreferrer"
              >
                <p className="mb-0">Follow us on Facebook</p>
                <FontAwesomeIcon icon={'fab fa-facebook'} size="2x" />
              </a>
            </div>
          </Col>

          <Col lg="3" md="6" className="mb-4">
            <h6 className="fw-bold">Explore</h6>
            <ListGroup className="link__list">
              {footerQuickLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item">
                  {item.target ? (
                    <a href={item.url} target={item.target}>
                      {item.display}
                    </a>
                  ) : (
                    <Link to={item.url}>{item.display}</Link>
                  )}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>

          <Col lg="3" md="6" className="mb-4">
            <h6 className="fw-bold">Information</h6>
            <ListGroup className="link__list">
              {footerInfoLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item">
                  {item.target ? (
                    <a href={item.url} target={item.target}>
                      {item.display}
                    </a>
                  ) : (
                    <Link to={item.url}>{item.display}</Link>
                  )}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>

          <Col lg="3" md="6">
            <h6 className="fw-bold">Get in Touch</h6>
            <Table borderless>
              <tbody>
                <tr>
                  <td style={{ paddingLeft: 0 }}>Address:</td>
                  <td>Binayi Triveni - 6, Nawalparasi</td>
                </tr>

                <tr>
                  <td style={{ paddingLeft: 0 }}>Phone:</td>
                  <td>
                    <a href="tel:+977-(0)78-560285" className="text-decoration-none">
                      +977-(0)78-560285
                    </a>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingLeft: 0 }}>Email:</td>
                  <td>
                    <a
                      href="mailto:info@bloomingbuds.edu.np"
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      info@bloomingbuds.edu.np
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>

            {/* <p>Address: Shivapurgadhi, Binayi Triveni - 7, Nawalparasi</p>
            <p>
              {' '}
              Phone:{' '}
              <a href="tel:+977-(0)78-560285" className="text-decoration-none">
                +977-(0)78-560285
              </a>{' '}
            </p>
            <p>
              Email:{' '}
              <a
                href="mailto:info@bloomingbuds.edu.np"
                target="_noblank"
                className="text-decoration-none"
              >
                info@bloomingbuds.edu.np
              </a>
            </p> */}
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="6">
            <div className="text-center mt-5">
              <p className="m-0 text-muted">
                Copyright {new Date().getFullYear()} © Blooming Buds. All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
