import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import courseImg1 from '../../assets/images/web-design.png';
import courseImg2 from '../../assets/images/graphics-design.png';
import courseImg3 from '../../assets/images/ui-ux.png';
import './notices.css';
import NoticeCard from './NoticeCard';

const Notices = () => {
  const notices = [
    {
      title: 'Web Design BootCamp-2022 for Beginners',
      publishedDate: '12th Jan 2022',
      imgUrl: courseImg1
    },

    {
      title: 'Professional Graphics Design',
      publishedDate: '12th Jan 2022',
      imgUrl: courseImg2
    },

    {
      title: 'UI/UX BootCamp for Beginners in 2022',
      publishedDate: '12th Jan 2022',
      imgUrl: courseImg3
    }
  ];
  return (
    <section>
      <Container>
        <h2 className="text-center mb-4">Notices</h2>
        <Row>
          <Col lg="12" className="mb-5">
            <div className=" text-center">
              <p>View our latest notices to students, teachers and parents.</p>
            </div>
          </Col>
          {notices.map((notice, index) => (
            <Col lg="4" md="6" sm="6" key={index}>
              <NoticeCard notice={notice} />
            </Col>
          ))}
        </Row>
        <div className="text-center">
          <button className="btn">View More</button>
        </div>
      </Container>
    </section>
  );
};

export default Notices;
