import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './hero-section.css';
import school from '../../assets/lotties/school.json';
import Lottie from 'lottie-react';
import { Link } from 'react-router-dom';
import Typist from 'react-typist-component';

const HeroSection = () => {
  return (
    <section className="pb-0">
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="hero__content text-xs-center">
              <h2 className=" hero__title">Get Enrolled Today</h2>
              <Typist>
                <h5 className="mb-4 ">where education matters...</h5>
              </Typist>
              <p className="mb-5">
                <strong>Make Change! </strong>
                <br /> To the way you think. To the path you take. <br /> To the lives of people all
                over the world.
              </p>
            </div>
            <Row className="justify-content-xs-center">
              <Col xs="6" lg="4" className="text-xs-center">
                <a href="https://forms.office.com/r/HS7x2HDXdM" target="_noblank">
                  <button className="btn">Admission</button>
                </a>
              </Col>
              <Col xs="6" lg="4" className="text-xs-center">
                <Link to={'/contact-us'}>
                  <button className="btn">Contact Us</button>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col lg="6" md="6">
            <Lottie animationData={school} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
