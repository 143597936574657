import React from 'react';
import Header from '../components/Header/Header';
import AboutSection from '../components/About-Section/About-Section';
import Footer from '../components/Footer/Footer';

const AboutUs = () => {
  return (
    <>
      <Header />
      <AboutSection />
      <Footer />
    </>
  );
};

export default AboutUs;
